import Autosuggest from 'react-autosuggest';
import React, { Component } from 'react';

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div>
        {suggestion}
    </div>
);

export default class Example extends React.Component {
    constructor() {
        super();

        // Autosuggest is a controlled component.
        // This means that you need to provide an input value
        // and an onChange handler that updates this value (see below).
        // Suggestions also need to be provided to the Autosuggest,
        // and they are initially empty because the Autosuggest is closed.
        this.state = {
            value: '',
            suggestions: []
        };
    }

    onChange = (event, { newValue }) => {
        this.setState({value: newValue})
    };


    componentDidMount(){
        this.setState({
            value: this.props.value ?  this.props.value :''
        })
    }

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {

        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
    
        let res =  inputLength === 0 ? [] : this.props.suggestions.filter(item =>
            item.toLowerCase().slice(0, inputLength) === inputValue
        );
    

        this.setState({
            suggestions: res
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: this.props.placeholder,
            value: this.state.value,
            onChange: this.onChange
        };

        // Finally, render it!
        return (

            <div className={this.props.error ? "form-field required" : "form-field"}>
                <label>{this.props.label}</label>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    onSuggestionSelected ={(event, {suggestion}) => {
                        this.props.onChange(suggestion)
                    }}
                    renderSuggestion={(suggestion) => {
                        return (
                            <div>
                                <span>{suggestion.substr(0, this.state.value.length)}</span>{suggestion.substr(this.state.value.length, suggestion.length)}
                            </div>
                        )
                    }}
                    inputProps={inputProps}
                />
            </div>


        );
    }
}
