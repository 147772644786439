import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom'
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';


import {
    Container,
    Row,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    CarouselControl,
    Carousel,
    CarouselItem

} from 'reactstrap';

import Image from '../components/image';


import moment from 'moment';

import searchIcon from '../assets/svg/search-icon-btn.svg';
import picture from '../assets/svg/picture-icon.svg';
import image1 from '../assets/images/image1.png';
import image2 from '../assets/images/image2.png';

import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';
import banner3 from '../assets/images/banner3.png';

import eyeIcon from '../assets/svg/eye.svg';

import imagesCount from '../assets/svg/images-count.svg';

import Slider from "react-slick";
import penIcon from '../assets/svg/orders-pen.svg';
import trashIcon from '../assets/svg/orders-trash.svg';
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,

    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIcon,
    ViberIcon,
    WhatsappIcon,

} from "react-share";
import {API_ENDPOINT} from '../constants';

class DetailPage extends Component {
    constructor(props) {
        super(props);
        this.init = this.init.bind(this);


        this.state = {
            ...props.initialData,
        };
    }


    init() {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props[0].match, this.props[0].location.pathname, null, this.props.lang).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state));
                })
            })
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.init()
    }

    componentDidUpdate(prevProps) {

        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.init();
        }
    }


    render() {
        let gallery = this.state.gallery ? this.state.gallery : {photos: []}

        if (gallery.error == 'notfound') {
            return (
                <Redirect to='/404'></Redirect>
            )
        }
        return (
            <div className="detail-wrap">
                <div className="into-wrap">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h2>{this.state.category && this.state.category.breadcrumb ? this.state.category.name : 'Pregled galerija'.translate(this.props.lang)}</h2>
                                {/*<h2>57.000 {'fotografija u ponudi'.translate(this.props.lang)}</h2>*/}
                            </Col>
                            <Col lg={{size: 6}}>
                                <div className="search-wrap">
                                    <Isvg src={picture}/>
                                    <input type="text"
                                           placeholder={'Unesite pojam za pretragu'.translate(this.props.lang)}
                                           value={this.state.search}
                                           onChange={(e) => this.setState({search: e.target.value})} onKeyUp={(e) => {
                                        if (e.keyCode == 13) {
                                            e.preventDefault();
                                            this.props[0].history.push(`/galerije?search=${encodeURIComponent(this.state.search)}`);
                                        }
                                    }}/>
                                    <button className="button" onClick={() => {
                                        this.props[0].history.push(`/galerije?search=${encodeURIComponent(this.state.search)}`);

                                    }}><Isvg src={searchIcon}/> {'PRETRAŽI'.translate(this.props.lang)}  </button>
                                </div>
                                <a onClick={() => this.props.handleDetailSearch(true)}
                                   className="detail-search">{'Napredna pretraga'.translate(this.props.lang)}</a>
                            </Col>
                        </Row>

                    </Container>
                </div>


                <section className="section-detail">
                    <Container>
                        <Row>
                            <Col lg="7" sm="12">
                                <h1>{Object.translate(gallery, 'name', this.props.lang)}</h1>
                                <div className="info">
                                    <div>{'Fotograf:'.translate(this.props.lang)} {Object.get(gallery, 'user')}</div>
                                    <div>{Object.get(gallery, 'location')} | {moment.unix(Object.get(gallery, 'date')).format('DD.MM.YYYY.')}
                                        <Isvg src={imagesCount}/> {gallery.photos && gallery.photos.length}</div>
                                </div>
                            </Col>
                            <Col lg={{size: 5, offset: 0}} sm={{size: 6, offset: 6}}>
                                <p className="share-desc">{'Hvala Vam što ste objavu podijelili na:'.translate(this.props.lang)}</p>
                                {typeof window !== 'undefined' ?
                                    <div className="share-actions">
                                        <FacebookShareButton url={window.location.href}> <FacebookIcon size={48}/>
                                        </FacebookShareButton>
                                        <TwitterShareButton url={window.location.href}><TwitterIcon
                                            size={48}/></TwitterShareButton>
                                        <LinkedinShareButton url={window.location.href}><LinkedinIcon
                                            size={48}/></LinkedinShareButton>
                                        <TelegramShareButton url={window.location.href}><TelegramIcon
                                            size={48}/></TelegramShareButton>
                                        <EmailShareButton url={window.location.href}><EmailIcon
                                            size={48}/></EmailShareButton>
                                        <ViberShareButton url={window.location.href}><ViberIcon
                                            size={48}/></ViberShareButton>
                                        <WhatsappShareButton url={window.location.href}><WhatsappIcon
                                            size={48}/></WhatsappShareButton>

                                    </div>

                                    :
                                    null

                                }


                            </Col>


                            <Col lg="12">
                                {
                                    (this.props.uData && this.props.uData.userRole == 'admin') || (this.props.uData && this.props.uData.userRole == 'photographer' && this.props.uData.permissions.indexOf('*') !== -1) ?
                                        <div className="acc-buttons">
                                            <Link to={`/account/gallery-photographer/${gallery.uid}/${gallery._id}`}>
                                                <button><Isvg src={penIcon}/> {'IZMJENI'.translate(this.props.lang)}
                                                </button>
                                            </Link>
                                            <button onClick={() => {
                                                this.props.handleDelete(() => {
                                                    fetch(`${API_ENDPOINT}/gallery/photographer/delete/${gallery.uid}/${gallery._id}`, {
                                                        method: 'DELETE',
                                                        headers: {
                                                            Accept: 'application/json',
                                                            //'Content-Type': 'multipart/form-data',
                                                            'Authorization': `Bearer ${localStorage.getItem('authToken')}`

                                                        },
                                                    }).then((res) => res.text()).then((img) => {
                                                        this.props[0].history.push('/')
                                                    });

                                                })
                                            }}><Isvg src={trashIcon}/> {'OBRIŠI'.translate(this.props.lang)}</button>

                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.props.uData && this.props.uData.userRole == 'photographer' && this.props.uData._id == gallery.uid ?
                                        <div className="acc-buttons">
                                            <Link to={`/account/gallery/${gallery._id}`}>
                                                <button><Isvg src={penIcon}/> {'IZMJENI'.translate(this.props.lang)}
                                                </button>
                                            </Link>
                                            <button onClick={() => {
                                                this.props.handleDelete(() => {
                                                    fetch(`${API_ENDPOINT}/gallery/delete/` + gallery._id, {
                                                        method: 'DELETE',
                                                        headers: {
                                                            Accept: 'application/json',
                                                            //'Content-Type': 'multipart/form-data',
                                                            'Authorization': `Bearer ${localStorage.getItem('authToken')}`

                                                        },
                                                    }).then((res) => res.text()).then((img) => {
                                                        this.props[0].history.push('/')
                                                    });

                                                })
                                            }}><Isvg src={trashIcon}/> {'OBRIŠI'.translate(this.props.lang)}</button>

                                        </div>
                                        :
                                        null
                                }

                                <div className="description">
                                    <p dangerouslySetInnerHTML={{__html: Object.translate(gallery, 'description', this.props.lang) ? Object.translate(gallery, 'description', this.props.lang).replace(/\n/g, '<br/>') : null}}></p>
                                </div>
                            </Col>
                            {
                                gallery.photos && gallery.photos.map((item, idx) => {
                                    return (
                                        <Col lg="3" sm="4" xs="6" key={idx}>

                                            <Link
                                                to={`/galerija/${Object.translate(gallery, 'alias', this.props.lang)}/${gallery._id}/${idx}`}>
                                                <article>
                                                    <img src={`${API_ENDPOINT}/photos/350x/` + item.image}/>
                                                    <div className="zoom-image">
                                                        <img src={`${API_ENDPOINT}/photos/350x/` + item.image}/>

                                                    </div>
                                                    <div className="zoom"><Isvg src={searchIcon}/></div>
                                                </article>
                                            </Link>
                                        </Col>

                                    )
                                })
                            }
                        </Row>
                    </Container>
                </section>


                <section className="section-banners">
                    <Container>
                        <Row>
                            <Col lg="12" className="banners">
                                {
                                    this.props.detailBanner ? this.props.detailBanner.images.map((item, idx) => {
                                            return (
                                                <a href={item.link} target="_blank"
                                                   onClick={() => this.props.bannerClick(item.link)}>
                                                    <img src={item.image} className="banner"/>
                                                </a>
                                            )
                                        })
                                        :
                                        null
                                }

                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        );
    }
}

export default Page(DetailPage);